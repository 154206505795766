<template>
  <div>
    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="名称">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="手机号">
            <a-input
              v-decorator="[
                'mobile',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="角色">
            <a-input
              v-decorator="[
                'duty',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="是否是负责人">
            <a-radio-group
              v-decorator="[
                'isCharger',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ],
                },
              ]"
            >
              <a-radio :value="true"> 是 </a-radio>
              <a-radio :value="false"> 否 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="是否接收短信通知">
            <a-radio-group
              v-decorator="[
                'isNotify',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ],
                },
              ]"
            >
              <a-radio :value="true"> 是 </a-radio>
              <a-radio :value="false"> 否 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="上传照片">
            <Upload :list="fileList" @change="onUpload" />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button htmlType="submit" type="primary">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import Upload from "@/components/upload";
import { add } from "@/api/risk/riskMember";
export default {
  components: { Upload },
  data() {
    return {
      form: this.$form.createForm(this),
      fileList: [],
    };
  },

  methods: {
    cancel() {
      this.$router.go(-1);
    },

    onUpload(val) {
      this.fileList = val;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let pictures = this.fileList.map((ele) => ele.completePath).join(",");
          add({ ...values, pictures }).then(() => {
            this.cancel();
          });
        }
      });
    },
  },
};
</script>
